// Variables
$fonts : 'Bebas Neue', sans-serif;
$size : 18px;
$lineheight : 28px;
$defaultColor : #DEB421;
$primaryColor : #000;

// Global CSS
*{
    outline: 0;
    box-sizing: border-box;

    ::selection {
        background-color: $defaultColor;
        color: inherit;
    }
}

body{
    overscroll-behavior: none;
    margin: 0;
    padding: 0;
    background-color: #000;
    font-family: $fonts;
    font-size: $size;

}

p, .p{
    font-family: 'Roboto', sans-serif;
    color: #fff;
}
a{
    text-decoration: none;
    transition: color 0.25s;
}
// General Classes
.max-width{
    max-width: 1110px;
    margin: auto;
    width: calc(100% - 30px);
}

h1, h2, h3, h4, h5, h6{
    font-family: $fonts;
}

.h1{
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 69px;
    margin: 0;
    margin-bottom: 24px;
    letter-spacing: -1px;
    color: #fff;
}
.clear{
    clear: both;
    float: none;
}
.h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    letter-spacing: -0.1px;
    margin: 0;
    margin-bottom: 55px;

    &.center{
        text-align: center;
    }
    span{
        font-family: 'bebas_neuebook';
        font-size: 32px;
        line-height: 32px;
        display: block;
        letter-spacing: 1px;
    }
}
.h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 32px;
    letter-spacing: -0.1px;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 10px;

    span{
        font-size: 19px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
    }

    &.center{
        text-align: center;
    }
}
.h4{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;
    color: $primaryColor;
    margin: 0;
}
.h5{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: $primaryColor;
    margin: 0;

    span{
        font-size: 18px;
    }
}
.button{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    padding: 12px 30px;
    background-color: $defaultColor;
    color: $primaryColor;
    text-transform: uppercase;
    border: 2px solid $defaultColor;
    transition: all 0.25s;
    cursor: pointer;

    &:disabled{
        background-color: #fff;
        cursor: no-drop;
    }
    &:hover{
        background-color: #fff;
    }

    &.full{
        width: 100%;
        height: auto;
    }
    &.black{
        background-color: #000;
        border-color: #000;
        color: #fff;
        text-align: center;

        &:hover{
            background-color: #fff;
            color: #000;
        }
    }
    &.gris{
        background-color: #333333;
        border-color: #333;
        color: #fff;
        text-align: center;

        svg, path{
            vertical-align: top;
            transition: all 0.25s;
        }
        &:hover{
            background-color: #fff;
            color: #333;

            svg, path{
                fill: #333
            }
        }
    }
    &.yellow{
        background-color: $defaultColor;
        border-color: $defaultColor;
        color: #333333;
        text-align: center;

        svg, path{
            vertical-align: top;
            transition: all 0.25s;
            fill: $defaultColor;
        }
        &:hover{
            background-color: #fff;

            svg, path{
                fill: #333
            }
        }
    }
    &.green{
        background-color: #1ED761;
        border-color: #1ED761;
        color: #fff;
        text-align: center;

        svg, path{
            vertical-align: top;
            transition: all 0.25s;
            fill: #fff;
        }
        &:hover{
            background-color: #fff;
            color: #1ED761;

            svg, path{
                fill: #1ED761
            }
        }
    }
}
.transparentBtn{
    display: inline-block;
    background-color: transparent;
    border: none;

    &.play {
        cursor: pointer;

        path {
            transition: all 0.25s;
        }

        &:hover{

            path {
                fill: $defaultColor;
            }
        }
    }
}
.wrap{
    position: relative;
}
.d-flex {
    display: flex;
}
.flex-1{
    flex: 1;
}
.m-0-80{
    margin: 0 80px;
}
.f-wrap{
    flex-wrap: wrap;
}
.jc-space-b{
    justify-content: space-between;
}
.jc-space-a{
    justify-content: space-around;
}
.jc-center{
    justify-content: center;
}
.align-center{
    align-items: center;
}
.align-fe{
    align-items: flex-end;
}
.align-fs{
    align-items: flex-start;
}
a.a{
    display: block;
    width: 100%;
    height: 100%;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.full{
    width: 100%;
    height: 100%;
}
.p-relative{
    position: relative;
}
.vs-mobile{
    display: none;
}
.slick-prev, .slick-next{
    z-index: 10;
    left: 25px !important;

    &:before{
        content: "" !important;
        width: 25px;
        height: 25px;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(135deg);
        display: block;
    }
}
.slick-next{
    left: auto !important;
    right: 25px !important;

    &:before{
        transform: rotate(-45deg);
    }
}

.table-colored{
    width: 100%;
    border-spacing: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;

    tbody{
        tr{


            &:nth-child(even){
                td{
                    background-color: #323232;
                }
            }
            &.active{
                color: $defaultColor;

                td{
                    svg, path{
                        fill: $defaultColor;
                    }
                }
            }
            td{
                padding-top: 12px;
                padding-bottom: 12px;
                border-top: 1px solid rgba(255, 255, 255, 0.1);

                a{
                    font-size: 16px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                    line-height: 24px;
                    color: $defaultColor;
                    white-space: nowrap;

                    .fa{
                        font-size: 12px;
                    }
                    &:hover{
                        color: #fff;
                    }
                }
                svg, path{
                    fill: #fff;
                }
                button{
                    vertical-align: text-top;
                }
                &:last-child{
                    padding-right: 5%;
                    text-align: right;
                    font-weight: 400;
                }
                &:nth-child(2){
                    font-weight: 400;
                }
                &:first-child{
                    padding-left: 5%;
                    width: 50%;
                }

            }
        }
    }
}
.table{
    width: 100%;
    margin-top: 50px;
    text-align: left;

    thead {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color:rgba(51, 51, 51, 0.4);
        th, td{
            padding-bottom: 10px;

            &:last-child{
                text-align: center;
            }
        }
    }

    tbody{
        tr{
            font-family: $fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.1px;
            font-family: 'bebas_neuebook';

            &.active{
                font-family: $fonts;
                color: $defaultColor;

                svg, path{
                    fill: $defaultColor;
                }
            }
            &:hover{
                td, th{
                    color: $defaultColor;
                }

                svg, path{
                    fill: $defaultColor;
                }
            }
            td, th{
                border-top: 1px solid #eee;
                padding: 10px 0;
                transition: all 0.25s;

                svg, path{
                    fill: #000;
                    transition: all 0.25s;
                }
                &:last-child{
                    text-align: center;
                }
                .addToPlayList{
                    background-color: transparent;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    line-height: 30px;
                    color: $defaultColor;
                    border: none;
                    height: auto;
                    padding: 0;
                    margin: 0;
                }

                > a {
                    color: inherit;
                }
            }
        }
    }
}
._404 {
    font-size: 200px;
    text-align: center;
    line-height: 220px;
    padding-top: 100px;
}
.notfound {
    font-size: 22px;
    padding-bottom: 100px;

    a{
        color: $defaultColor;
    }
}
._visible-sm{
    display: none;
}
/*
 *
 * => Header
 *
 ****/
 .header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: $primaryColor;
    padding: 13px 0;
    z-index: 1024;

    .navbar{

        .brand{
            height: 36px;

            a {
                img{

                }
            }
        }

        .navmenu{

            .btnToggle{
                display: none;
            }
            .menu{
                ul{
                    list-style-type: none;
                    margin: 0;
                    padding: 0;

                    li{
                        margin: 0 37.5px;
                        &.active{
                            a{
                                color: $defaultColor;
                            }
                        }
                        a{
                            color: #fff;
                            font-size: 24px;
                            line-height: 24px;
                            text-align: center;
                            letter-spacing: 0.1px;
                            &:hover{
                                color: $defaultColor;
                            }
                            &.active{
                                color: $defaultColor;
                            }
                        }

                    }
                }
            }
        }

        .LoginButton{
            button,
            a{
                background: none;
                border: 0;
                color: $defaultColor;
                font-family: "Bebas Neue",sans-serif;
                font-size: 18px;
                padding: 0;

                &:hover{
                    color: #fff;
                }
                img{
                    vertical-align: middle;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .navbar{
            .brand{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 3px;

                img{
                    width: 120px;
                }
            }
            .LoginButton {
                a{
                    font-size: 0;
                }
            }
            .navmenu{

                &.on{
                    .btnToggle{
                        span{
                            &:first-child{
                                transform: translate(0px, 10px) rotate(45deg);
                            }
                            &:nth-child(2){
                                transform: rotate(-45deg);
                            }
                            &:last-child{
                                opacity: 0;
                            }
                        }
                    }
                    .menu{
                        transform: translate(0%);
                        opacity: 1;
                    }
                }
                .btnToggle{
                    position: relative;
                    top: 5px;
                    width: 35px;
                    height: 25px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    span{
                        height: 3px;
                        background-color: $defaultColor;
                        transition: all 0.25s;
                    }
                }

                .menu{
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    background-color: #000;
                    max-width: 260px;
                    box-shadow: 0 0 20px #191919;
                    width: 100%;
                    transform: translate(100%);
                    opacity: 0;
                    transition: all 0.25s;

                    ul{
                        display: flex;
                        flex-direction: column;
                        padding-top: 45px;

                        li{
                            padding: 15px 0;
                        }
                    }
                }
            }
        }
    }
 }


 /*
 *
 * => Content
 *
 ****/
 .content{
     padding-top: 60px;
    }


/**
**
** => Banner
**
****/
.banner{
    padding-top: 20.575%;
    padding-bottom: 20.575%;
    min-height: 320px;
    position: relative;
    border-top: 10px solid $defaultColor;
    border-bottom: 10px solid $defaultColor;

    &:first-child{
        border-top: none;
    }
    .wrapper{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .caption{
        max-width: 460px;

        p, .p{
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
        }
    }
    .Headerslider{
        .item{
            padding-top: 20.575%;
            padding-bottom: 20.575%;
            min-height: 320px;
            background-size: cover;
            background-position: center;
            position: relative;

            .img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                background-size: cover;
                background-position: left;
                padding-left: 50px;
                padding-right: 50px;

            }
        }
    }

    @media screen and (max-width: 1199px){

        padding-top: 26.575%;
        padding-bottom: 26.575%;

        .Headerslider {
            .item{
                padding-top: 26.575%;
                padding-bottom: 26.575%;
            }
        }
    }
    @media screen and (max-width: 767px){
        //padding-top: 49%;
        //padding-bottom: 49%;

        .caption{
            text-align: center;
            position: relative;
            z-index: 15;
            max-width: 390px;
            margin-left: auto;
            margin-right: auto;
        }
        .button{
            display: block;
            text-align: center;
        }
        .slick-dots li button:before, .slick-dots li.slick-active button:before{
            color: #fff;
        }
        .slick-dots{
            bottom: 45px;
        }
        .Headerslider {
            .item{
                //padding-top: 49%;
                //padding-bottom: 49%;

                .img{
                    z-index: 10;
                    //
                    //&:before{
                    //    content: "";
                    //    position: absolute;
                    //    left: 0;
                    //    top: 0;
                    //    width: 100%;
                    //    height: 100%;
                    //    background-color: rgba(0, 0, 0, 0.5);
                    //    z-index: 1;
                    //}
                }
            }
        }
    }
    @media screen and (max-width: 480px){
        min-height: 420px;

        .Headerslider {
            .item {
                min-height: 420px;

                .img{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

/**
**
** => playLists
**
****/
.playList{
    background-image: url(../img/playlist-Background.jpg);
    padding-top: 62px;
    padding-bottom: 55px;

    .items{
        flex-wrap: wrap;

        .slick-slide > div {
            margin: 0 15px;
        }
        .slick-list {
            margin: 0 -15px;
        }

        .item{
            //width: calc(33.33% - 20px);

            .img{
                position: relative;
                padding-top: 57%;
                background-size: cover;
                background-position: center;

                &--square {
                    padding-top: 100%;
                }

                .playBtn {
                    background: none;
                    padding: 0;
                    width: 59px;
                    height: 59px;
                    border-radius: 50%;
                    border: 4px solid #fff;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    line-height: 57px;
                    text-align: center;
                    font-size: 26px;
                    padding-left: 4px;
                    color: #fff;
                    cursor: pointer;
                    transition: all 0.25s;

                    &:hover{
                        border-color: $defaultColor;
                        color: $defaultColor;
                    }
                }

                > span {
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }
            }
            .h3{
                color: #fff;

                span{
                    font-weight: 100;
                }
            }
            .p, p{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #888888;
            }
            .listenNow {
                font-size: 16px;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                line-height: 24px;
                color: $defaultColor;

                .fa{
                    font-size: 12px;
                }
                &:hover{
                    color: #fff;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        padding-left: 50px;
        padding-right: 50px;

        .slick-next{
            right: -25px !important;
        }
        .slick-prev{
            left: -25px !important;
        }
        .slick-dots li button:before, .slick-dots li.slick-active button:before{
            color: #fff;
        }
    }

    @media screen and (max-width: 767px) {
        border-top: 10px solid $defaultColor;

        .slick-prev, .slick-next{
            top: calc(50% + 40px);
        }
        .slick-next{
            right: 25px !important;
        }
        .slick-prev{
            left: 25px !important;
        }
        .items{
            max-width: 390px;
            margin: auto;

            .item {
                text-align: center;

                .img{

                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
        border-top: 10px solid $defaultColor;

        .items{
            max-width: 325px;
        }
    }
}

/**
**
** => Login & Registration
**
****/
.login{
    padding-top: 180px;
    padding-bottom: 165px;
    background-size: cover;
    background-position: center;

    .h2{
        font-size: 48px;
        line-height: 48px;
        color: #333;
        letter-spacing: -0.1px;
        margin-bottom: 32px;
    }
    ._rlp{
        ._cllp{
            width: calc(50% - 15px);

            > div{
                max-width: 360px;
                margin: auto;
            }
        }
    }
    ._frm{
        .p, p{
            color: #333;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }
    ._frmg{
        margin-bottom: 15px;
        input[type="text"],
        input[type="password"]{
            height: 48px;
            background-color: #E0E0E0;
            width: 100%;
            color: #333;
            padding: 10px;
            border: none;
            font-size: 16px;
        }
        ::placeholder{
            color: rgba(51, 51, 51, 0.4);
            font-family: 'Roboto', sans-serif;
        }
        :-ms-input-placeholder{
            color: rgba(51, 51, 51, 0.4);
            font-family: 'Roboto', sans-serif;
        }
        ::-webkit-input-placeholder{
            color: rgba(51, 51, 51, 0.4);
            font-family: 'Roboto', sans-serif;
        }

        .forgotPassword {
            float: right;

            a {
                color: black;
                opacity: 0.5;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding-top: 60px;
        padding-bottom: 40px;

        ._rlp {
            flex-wrap: wrap;

            ._cllp{
                width: 100%;

                &:first-child{
                    margin-bottom: 40px;
                }
            }
        }
    }
}
/**
**
** => myPlayLists
**
****/

.myPlayList{
    background-image: url(../img/playlist-Background.jpg);
    padding-top: 62px;
    padding-bottom: 55px;

    .h2,
    .h3{
        color: #fff;

        path {
            fill: white;
        }
    }
    .items{
        flex-wrap: wrap;

        .slick-slide > div {
            margin: 0 15px;
        }
        .slick-list {
            margin: 0 -15px;
        }

        .item{
            //width: calc(33.33% - 20px);

            .img{
                position: relative;
                padding-top: 100%;
                background-size: cover;
                background-position: center;

                > span {
                    left: 0;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    width: 100%;
                }

                .playBtn {
                    background: none;
                    padding: 0;
                    width: 59px;
                    height: 59px;
                    border-radius: 50%;
                    border: 4px solid #fff;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    line-height: 57px;
                    text-align: center;
                    font-size: 26px;
                    padding-left: 4px;
                    color: #fff;
                    cursor: pointer;
                    transition: all 0.25s;

                    &:hover{
                        border-color: $defaultColor;
                        color: $defaultColor;
                    }
                }
            }
            .h3{
                color: #fff;

                span{
                    font-weight: 100;
                }
            }
            .p, p{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #888888;
            }
            .listenNow {
                font-size: 16px;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                line-height: 24px;
                color: $defaultColor;

                .fa{
                    font-size: 12px;
                }
                &:hover{
                    color: #fff;
                }
            }
        }
    }

    .slick-next{
        right: -25px !important;
    }
    .slick-prev{
        left: -25px !important;
    }
    @media screen and (max-width : 991px) {
        padding-left: 30px;
        padding-right: 30px;
        .slick-prev, .slick-next{
            top: 110px;
        }
    }
    @media screen and (max-width : 767px) {
        padding-left: 0;
        padding-right: 0;

        .items{
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;
            width: calc(100% - 80px);
        }

        .slick-dots li.slick-active button:before, .slick-dots li button:before{
            color: #fff;
        }
    }
    @media screen and (max-width : 480px) {
        .h2{
            text-align: center;
        }
        .slick-next{
            right: 10px !important;
        }
        .slick-prev{
            left: 10px !important;
        }

        .slick-prev, .slick-next {
            top: calc(50% + 120px);
        }
        .items{
            max-width: 325px;
            width: auto;

            .item{
                text-align: center;
            }
        }
    }
}

/**
**
** => Single Playlist
**
****/
.single-playlist{
    padding: 60px 150px;
    background-color: #000;

    .h2{
        margin: 0;
        padding: 20px 0;

        button {
            padding: 6px;

            svg {
                height: 30px;
                width: 30px;

                path {
                    fill: white
                }
            }
        }
    }

    .delete-playlist {
        text-align: right;
        margin-right: 20px;
        margin-top: 20px;

        button {
            background: none;
            border: none;
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            line-height: 24px;
            color: $defaultColor;
            transition: all 0.25s;
            cursor: pointer;

            &:hover{
                color: #fff;
            }
        }
    }

    .singlePlayListSlider{
        .slick-next, .slick-prev{
            top: 0;
            left: calc(100% + 15px) !important;
            right: auto !important;
            height: 100%;
            transform: none;
            width: 150px;
            display: flex !important;
            align-items: center;
            padding-left: 80px;
            background-color: rgba(136, 136, 136 , 0.5);
            transition: all 0.25s;

            &:hover{
                background-color: rgba(136, 136, 136 , 1);
            }
        }
        .slick-prev{
            right: calc(100% + 15px) !important;
            left: auto !important;
            justify-content: flex-end;
            padding-left: 0;
            padding-right: 80px;
        }
        .item{
            background-color: #1E1E1E;
            color: #fff;
            padding-bottom: 10px;
        }
    }

    @media screen and (max-width: 1439px) and (min-width: 1250px){
        padding-left: calc(50vw - 570px);
        padding-right: calc(50vw - 570px);
        .singlePlayListSlider {
            .slick-next, .slick-prev{
                width: calc(50vw - 570px);
                justify-content: center;
                padding: 0;
            }
        }
    }
    @media screen and (max-width: 1249px){
        padding-left: 45px;
        padding-right: 45px;
        .singlePlayListSlider {
            .slick-next, .slick-prev{
                width: 34px;
                justify-content: center;
                padding: 0;
                left: calc(100% + 10px) !important;
                right: auto !important;

                &:before{
                    width: 15px;
                    height: 15px;
                }
            }
            .slick-prev{
                right: calc(100% + 10px) !important;
                left: auto !important;
            }
        }
    }
    @media screen and (max-width: 767px){
        padding-left: 0;
        padding-right: 0;

        .slick-dots {
            bottom: -35px;
            li.slick-active button:before, li button:before{
                color: #fff;
            }
        }
        .singlePlayListSlider {
            .item{
                padding-bottom: 0;
            }
        }
    }
}
/**
**
** => Full Map
**
****/
.fullmap{
    padding-top: 80px;
    padding-bottom: 75px;
    background-size: cover;
    background-position: center;

    .map{
        border-radius: 20px;
        padding: (540/1110)*100% 0 0;
        position: relative;
        overflow: hidden;

        > div,
        img{
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}
/**
**
** => My Shows Section
**
****/
.MyShowsSection{
    padding-top: 40px;
    padding-bottom: 60px;
    background-size: cover;
    background-position: center;
    border-bottom: 10px solid $defaultColor;
    border-top: 10px solid $defaultColor;

    .h2{
        margin: 0;
        margin-bottom: 20px;
    }
    .items{
        margin: 0 -15px;

        .item{
            width: calc(25% - 30px);
            margin: 0 15px;
            background-color: #fff;
            margin-bottom: 54px;

            .img{
                padding-top: 56.5%;
                position: relative;
                overflow: hidden;

                > span {
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                &:hover{
                    .bg{
                        transform: scale(1.2);
                    }
                }
                .bg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    transform: scale(1);
                    transition: all 0.25s;
                    // cursor: pointer;
                }
                .delete{
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    z-index: 10;
                    cursor: pointer;
                    padding: 0;

                    svg, path{
                        fill: #fff;
                    }

                    &:hover{
                        svg, path{
                            fill: $defaultColor;
                        }
                    }
                }

            }
            ._dts{
                padding: 28px;

                .h3{

                }
                .h4{

                }
                .play{
                    display: block;
                    padding: 0;
                    margin: 12px 0;
                    cursor: pointer;

                    svg, path{
                        fill: $primaryColor;
                        transition: all 0.25s;
                    }

                    &:hover{
                        svg, path{
                            fill: $defaultColor;
                        }
                    }
                }
                .show-details{
                    font-family: 'Roboto', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                    color: #000000;
                    text-transform: uppercase;

                    &:hover{
                        color: $defaultColor;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1199px) {
        .items{
            .item{
                width: calc(33.33% - 30px);
            }
        }
    }
    @media screen and (max-width: 991px) {
        .items{
            .item{
                width: calc(50% - 30px);
            }
        }
    }
    @media screen and (max-width: 767px) {
        background-image: url(../img/playlist-Background.jpg) !important;

        .h2{
            color: #fff;
        }
        .items{
            .item{
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 54px;
    }
}
/**
**
** => Show details
**
****/
div.show-details{
    background-size: cover;
    background-position: center;
    padding: 115px 0;

    ._sdrw{
        ._sdcl{
            width: 50%;
            img{
                display: block;
                max-width: 351px;
            }
            &:first-child{
                padding-right: 30px;
            }

            .goBack{
                color: rgba(255,255,255,0.4);
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 36px;
                font-family: 'Roboto', sans-serif;
            }
            .date{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 36px;
                font-family: 'Roboto', sans-serif;
                color: #fff;
                letter-spacing: 1px;

                span{
                    font-weight: 200;
                }
            }

            .entry-name {
                font-size: 64px;
                color: #fff;
                line-height: 64px;
                letter-spacing: 1px;
                font-weight: 400;
                margin-bottom: 20px;

                span{
                    font-family: 'bebas_neuebook';
                    font-size: 41px;
                    line-height: 41px;
                    display: block;
                }
            }

            .rating {
                margin-top: 20px;
            }

            .play {
                svg,
                path {
                    fill: white;
                    height: 50px;
                    width: 50px;
                }
            }

            .buttons{
                margin: 0 -13px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 33px;

                .button{
                    margin: 0 13px;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        ._sdrw {
            flex-direction: column;

            ._sdcl{
                width: 100%;
                max-width: 560px;
                text-align: center;


                &:first-child{
                    padding-right: 0;
                }
                img{
                    margin: auto;
                    margin-bottom: 40px;
                    max-width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 55px 0;

        ._sdrw {
            ._sdcl {
                .buttons{
                    flex-wrap: wrap;
                    max-width: 390px;
                    margin: auto auto 30px auto;

                    .button{
                        padding: 12px 10px;

                        &:first-child{
                            width: 100%;
                            margin-bottom: 15px;
                        }
                        &:last-child, &:nth-child(2){
                            flex: 1;
                        }
                    }

                    + .button{
                        display: block;
                        margin: 0 -15px;
                        width: auto;
                    }
                }
                .entry-name {
                    span{
                        font-size: 31px;
                        line-height: 31px;
                    }
                }
            }
        }
    }
}


/**
**
** => Set list
**
****/
.set-list{
    background: white;
    padding-top: 90px;
    padding-bottom: 144px;

    .entry-title{
        font-size: 64px;
        line-height: 60px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 90px;
    }

    .lists{
        .list{
            margin-bottom: 50px;
            float: left;
            width: 50%;

            .list-title{
                font-size: 42px;
                line-height: 48px;
                letter-spacing: -0.1px;
                color: $defaultColor;
                margin-bottom: 12px;
                background-color: transparent;
                border: none;
                font-family: inherit;
                transition: all 0.25s;
            }

            button.list-title {
                cursor: pointer;

                &:hover{
                    color: #333;

                    svg, path{
                        fill: #333;
                    }
                }
                svg, path{
                    fill: $defaultColor;
                }
            }

            ul{
                list-style-type: none;
                padding: 0;margin: 0;

                li{
                    font-size: 24px;
                    line-height: 24px;
                    letter-spacing: -0.1px;
                    font-weight: 500;
                    font-family: 'Roboto', sans-serif;
                    color: #333;
                    margin-bottom: 20px;

                    a:hover{
                        color: $defaultColor;
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        color: inherit;
                    }
                    button{
                        cursor: pointer;

                        svg, path{
                            transition: all 0.25s;
                        }
                        &:hover{
                            svg, path{
                                fill: $defaultColor;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width : 991px) {
        .lists {
            .list {
                ul {
                    li{
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width : 767px) {
        padding-top: 50px;
        padding-bottom: 35px;

        .entry-title{
            margin-bottom: 40px;
        }
        .lists {
            .list{
                width: 100%;
            }
        }
    }
    @media screen and (max-width : 480px) {
        .lists{
            .list {
                ul {
                    li {
                        button{
                            &.info{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
**
** => Fan Submitted
**
****/
.fan-submitted{
    background-color: #000;
    padding-top: 95px;
    padding-bottom: 85px;

    .h2{
        color: #fff;
        font-size: 64px;
        line-height: 64px;
    }
    .slick-list{
        width: 100%;
    }
    .items{
        margin: 0 -15px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;

        .item{
            position: relative;
            margin: 0;
            padding: 0 15px;
            width: calc(25% - 30px);
            margin-bottom: 30px;
            color: #fff;

            .img{
                padding-top: 56.5%;
                position: relative;
                overflow: hidden;

                &:hover{
                    .bg{
                        transform: scale(1.1);
                    }
                }
                .bg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    transform: scale(1);
                    transition: all 0.25s;
                    cursor: pointer;
                }
            }

            .h5{
                font-family: $fonts;
                color: #fff;
                margin-top: 16px;
                margin-bottom: 10px;
            }
            .username{
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                font-family: 'Roboto', sans-serif;
                color: #FFFFFF;
            }
        }
    }

    @media screen and (max-width : 991px) {
        .items{
            margin-bottom: 50px;
        }
        .slick-dots li button:before, .slick-dots li.slick-active button:before{
            color: #fff;
        }
    }
    @media screen and (max-width : 767px) {
        .h2{
            font-size: 48px;
            line-height: 48px;
            text-align: center;
        }
    }
    @media screen and (max-width : 480px) {
        .items{
            .item{
                text-align: center;
                padding: 0 30px;
            }
        }
        .slick-prev{
            left: 30px !important;
            top: 80%;
        }
        .slick-next{
            right: 30px !important;
            top: 80%;

        }
    }

}

/**
**
** => Photos
**
****/
.photos{
    background-color: #000;
    padding-top: 85px;
    padding-bottom: 135px;
    border-top: 10px solid $defaultColor;

    .h2{
        color: #fff;
        font-size: 64px;
        line-height: 64px;
    }
    .slick-list{
        width: calc(100%);
    }

    /* the slides */
    .slick-slide {
        padding: 0 15px;
    }

    .items{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        .item{
            position: relative;
            display: block;
            overflow: hidden;
            width: calc(25% - 30px);
            margin-bottom: 30px;

            > div{
                padding-top: 100%;
            }

            .img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transform: scale(1);
                transition: all 0.25s;
                background-size: cover;
                background-position: center;

                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        padding-top: 35px;
        padding-bottom: 35px;

        .slick-dots li.slick-active button:before, .slick-dots li button:before{
            color: #fff;
        }
        .slick-dots{
            bottom: -15px;
        }
    }
    @media screen and (max-width: 767px) {
        .h2{
            font-size: 48px;
            line-height: 48px;
            text-align: center;
        }
    }
}
/**
**
** => SingleShow
**
****/
.singleshow{
    padding-top: 80px;
    padding-bottom: 75px;
    background-size: cover;
    background-position: center;

    ._clh{
        width: 50%;

        &._clh-right{
            padding-left: 105px;
        }

        .h2{
            color: $defaultColor;
            margin: 0 0 10px 0;
        }
        .details{

            .item{
                width: 50%;
                margin-bottom: 30px;

                .num{
                    font-size: 96px;
                    color: #fff;
                    line-height: 106px;
                    letter-spacing: 0.1px;
                    font-weight: 400;

                    img{
                        vertical-align: middle;
                    }

                    + div{
                        font-size: 36px;
                        line-height: 17px;
                        color: $defaultColor;
                    }
                }

            }
        }
    }
    .map{
        border-radius: 20px;
        min-height: 480px;
        overflow: hidden;
        padding: (532/615)*100% 0 0;
        position: relative;

        > div {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        img{
            width: 100%;
            display: block;
        }
    }

    @media screen and (max-width: 1199px) {
        ._clh{
            &._clh-right{
                padding-left: 15px;
            }

            .details {
                .item{
                    &:last-child{
                        margin-bottom: 0;
                    }

                    .num{
                        line-height: 90px;
                        +div{
                            font-size: 31px;
                            line-height: 31px;
                        }
                    }
                }
            }
        }
        .map{
            min-height: 380px;
        }
    }
    @media screen and (max-width: 991px) {
        .map{
            min-height: 310px;
        }
        ._clh {
            .details {
                .item {

                    .num{
                        font-size: 60px;
                        line-height: 60px;

                        img{
                            max-height: 29px;
                        }
                        +div{
                            font-size: 24px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .map{
            display: none;
        }
        ._clh{
            width: 100%;
        }
    }
    @media screen and (max-width: 480px) {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        ._clh {
            .details {
                .item{
                    width: 100%;

                    .num {
                        img{
                            position: absolute;
                            margin-top: 11px;
                            margin-left: 7px;
                        }
                    }
                }
            }
        }
    }
}
/**
**
** => Single Song
**
****/
.single-song{
    background-color: #000;
    padding: 80px 0;

    .h1{
        font-weight: 400;

        span{
            display: block;
            font-size: 36px;
            line-height: 30px;
            font-family: 'bebas_neuebook', sans-serif;
            letter-spacing: 1px;
            color: rgba(255,255,255,0.6);

            b{
                font-family: "Bebas Neue",sans-serif;
                font-weight: 400;
                color: #fff;
            }
        }
        button{
            vertical-align: middle;
        }
        svg, path{
            fill: #fff;
            vertical-align: middle;
            width: 30px;
            height: 30px;
        }
    }
    .h2{
        color: #fff;
        font-size: 48px;
        line-height: 48px;
        font-family: 'bebas_neuebook', sans-serif;
        letter-spacing: 1px;
        margin-bottom: 30px;

        span{
            display: inline-block;
            font-size: 45px;
            font-weight: 400;
            font-family: $fonts;
            color: #d8b445;
        }
    }
    ._rwss{
        ._clss{
            width: 50%;

            &:first-child{
                padding-right: 30px;

                img{
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
    }
    .items{
        > div{
            width: 50%;
            color: #fff;
            margin-bottom: 40px;

            .entry-n{
                font-size: 36px;
                line-height: 32px;
                letter-spacing: -0.1px;
                font-family: 'bebas_neuebook', sans-serif;
                color: rgba(255,255,255,0.6);
            }
            .entry-d{
                font-size: 48px;
                line-height: 48px;
                letter-spacing: -0.1px;
            }
            .entry-p{
                margin-top: 10px;

                svg:last-child{
                    vertical-align: bottom;
                    margin-right: 10px;
                }
            }
        }
    }

    @media screen  and (max-width: 1199px){
        .h1{
            span{
                font-size: 30px;
            }
        }
        .h2{
            font-size: 38px;
            line-height: 38px;

            span{
                font-size: 38px;
            }
        }
        .items{
            >div {
                margin-bottom: 30px;

                .entry-d{
                    font-size: 38px;
                    line-height: 38px;
                }
                .entry-p{
                    svg{
                        height: 30px;
                    }
                }
                .entry-n{
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }

        @media screen  and (max-width: 991px){
            ._rwss {
                justify-content: center;

                ._clss{
                    width: 100%;
                    max-width: 500px;


                    &:first-child{
                        margin-bottom: 40px;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    @media screen and (max-width : 480px) {
        padding: 50px 0;

        .h1{
            font-size: 64px;

            button{
                margin: 15px auto;
                display: block;
            }
            span{
                font-size: 24px ;
            }
        }
        .h2 {
            font-size: 24px;
            line-height: 24px;
            margin: 30px 0;

            span{
                font-size: 24px;
            }
        }
        .items{
            >div{
                width: 100%;

                .entry-n{
                    font-size: 24px;
                    line-height: 24px;
                    display: inline-block;
                    margin-right: 4px;
                }
                .entry-d{
                    font-size: 24px;
                    line-height: 24px;
                    display: inline;
                    color: $defaultColor;
                }
                .entry-p {
                    svg, path{
                        fill: $defaultColor;
                    }
                }
            }
        }
        ._rwss {
            ._clss{
                text-align: center;
            }
        }
    }
}
/**
**
** => All Shows
**
****/
.myShows{
    background: white;
    border-bottom: 10px solid $defaultColor;
    border-top: 10px solid $defaultColor;
    padding-top: 70px;
    padding-bottom: 85px;

    &._ylwsh{
        padding-bottom: 0;
        background-color: #DEB421;
        border-bottom: none;

        ._bgwh{
            background-color: #fff;
            padding-top: 15px;
            padding-bottom: 85px;
            margin-top: 65px;
        }

        .search {
            input{
                background-color: transparent;
                border-color: #000;
                border-width: 2px;
            }
        }
        .ul {
            .li {
                select{
                    &.active{
                        color: #000;
                        border-bottom: 2px solid #000;
                        background-image: url(../img/chevron-down-blk.png);
                    }
                }
            }
        }
    }
    .h2{
        color: #333333;
        margin: 0;
        line-height: 48px;
        margin-right: 40px;
    }

    .clear-filter {
        display: block;
        font-family: "Bebas Neue",sans-serif;
        font-size: 18px;
        padding: 0;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 60px;
    }

    .filter{
        padding-left: 30px;
        font-size: 24px;
        color: #333;
        letter-spacing: 0.1px;
    }

    .filterSelect {
        font-size: 24px;
        border-bottom: 2px solid rgba(0,0,0,0.6);
        overflow: hidden;
        padding: 0 0 10px;
        position: relative;
        min-width: 85px;
        max-width: 120px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;

        label {
            color: #black;
            opacity: 0.6
        }

        &.filled {
            border-bottom: 2px solid black;

            label {
                opacity: 1
            }
        }

        select {
            border: 1px solid transparent;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            opacity: 0;
            width: 100%;
        }
    }

    .ul{
        padding-left: 70px;

        // .li{
        //     &:last-child{
        //         padding-right: 0;
        //     }


        //     select{
        //         font-size: 24px;
        //         color: rgba(51, 51, 51, 0.4);
        //         padding: 6px 15px;
        //         border: none;
        //         -webkit-appearance: none;
        //         appearance: none;
        //         display: block;
        //         background-image: url(../img/chevron-down.png);
        //         background-position: right center;
        //         background-repeat: no-repeat;
        //         background-size: 11px;
        //         background-color: transparent;
        //         font-family: inherit;
        //         border-bottom: 2px solid transparent;
        //         &.active{
        //             color: $defaultColor;
        //             border-bottom: 2px solid $defaultColor;
        //             background-image: url(../img/chevron-down-ylw.png);
        //         }
        //         &::-ms-expand {	display: none; }
        //         option{
        //             color: rgba(51, 51, 51, 0.4);
        //             transition: all 0.25s;
        //             //font-size: 19px;

        //             &:hover{
        //                 color: $defaultColor;
        //             }
        //         }
        //     }
        // }
    }
    .search{
        label{
            color: #323232;
        }
        input{
            padding: 7px 0;
            border: none;
            border-bottom: 1px solid rgba(51, 51, 51, 0.4);
            margin: 0 40px 0 20px;
            width: 260px;
            font-family: "Bebas Neue",sans-serif;
            font-size: 24px;
            border-radius: 0;
            color: rgba(51, 51, 51, 1);
        }
        ::placeholder{
            color: rgba(51, 51, 51, 0.4);
            font-family: 'bebas_neuebook';
        }
        :-ms-input-placeholder{
            color: rgba(51, 51, 51, 0.4);
            font-family: 'bebas_neuebook';
        }
        ::-webkit-input-placeholder{
            color: rgba(51, 51, 51, 0.4);
            font-family: 'bebas_neuebook';
        }
    }

    @media screen and (max-width: 1199px){
        .ul{
            padding-left: 40px;
        }
        .filter{
            padding-left: 0;
        }
    }
    @media screen and (max-width: 991px){
        background-color: $defaultColor;
        padding-bottom: 0;
        border-bottom: none;

        &._ylwsh {
            ._bgwh{
                padding-bottom: 0;
            }
        }
        .head {
            flex-direction: column;
            align-items: center;

            > div {
                width: 100%;
                max-width: 317px;
                text-align: center;
            }

            .h2 {
                margin-right: 0;
            }

            .search {
                input {
                    margin: 20px 0;
                    background-color: transparent;
                    color: rgba(51, 51, 51, 1);

                }


                .fa-search {
                    display: inline-block;
                    height: 46px;
                    padding-right: 20px;
                    padding-left: 20px;
                    line-height: 46px;
                    border-bottom: 1px solid rgba(51, 51, 51, 0.4);
                    color: #333;
                }
            }

            .filter {
                display: none;
            }

            .clear-filter {
                margin: 20px 0;
                position: relative;
                top: 0;
            }
        }

        .ul {
            padding-left: 0;

            .li{
                select{
                    &.active{
                        color: #000;
                        border-color: #000;

                        background-image: url(../img/chevron-down-blk.png);
                    }
                }
            }
        }

    }
    @media screen and (max-width: 767px){
        &._ylwsh {
            ._bgwh{
                padding-bottom: 0;
            }
        }
    }
}
/**
**
** => Footer
**
****/
.footer{
    padding: 80px 0 70px 0;
    background-color: #000;
    border-top: 10px solid $defaultColor;


    .brand{
        min-width: 156px;
    }
    .navmenu{
        ul{
            list-style-type: none;
            padding: 0;
            margin: 0;

            li{
                display: inline-block;
                margin: 0 12.5px;

                a{
                    color: #fff;
                    letter-spacing: 0.1px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: 'Roboto', sans-serif;
                    text-transform: uppercase;
                }

                &:hover, &.active{
                    a{
                        color: $defaultColor;
                    }
                }
            }
        }
    }
    .SM{
        min-width: 156px;

        ul{
            list-style-type: none;
            padding: 0;
            margin: 0;

            li{
                display: inline-block;
                padding-left: 12px;

                a{
                    color: #fff;
                }

                &:hover{
                    a{
                        color: $defaultColor;
                    }
                }
            }

        }
    }

    .copyright{
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: rgba(225, 225, 225, 0.4);

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 767px) {
        .navbar-footer{
            flex-direction: column;
            text-align: center;

            .navmenu{
                margin: 30px 0;
            }

            .SM{
                text-align: center;
                margin: 30px 0;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .navbar-footer{
            flex-direction: column;
            text-align: left;

            .navmenu{
                margin: 30px 0;
                ul{
                    text-align: left;
                    li{
                        display: block;
                        margin: 5px 0;
                    }
                }
            }

            .SM{
                text-align: left;
                margin: 30px 0;
            }
        }

        .copyright{
            text-align: left;
        }
    }
}
._dps-player {
    position: relative;
    padding-bottom: 76px;

    &.hidden {
        pointer-events: none;
        opacity: 0;
        position: absolute;
    }

    &._fxpl{
        .wrapper{
            position: fixed;
        }
    }
    .wrapper {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #000;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        color: #fff;
        z-index: 100;

        ._cl76 {
            width: 76px;

            img{
                display: block;
            }
        }
        .musicMeta {
            flex-direction: row;
        }
        .musicName{
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.1px;
            display: inline-block;
            margin-right: 15px;
        }
        .musicDate{
            font-size: 18px;
            line-height: 18px;
            color: rgb(99, 105, 105);
            margin-bottom: 5px;
        }
        .controls{
            .prevAudio,
            .nextAudio {
                background: none;
                cursor: pointer;
                border: none;
                color: #fff;
                padding: 0;
            }

            .pauseAudio,
            .playAudio {
                background: none;
                cursor: pointer;
                margin: 0 38px;
                font-size: 20px;
                display: inline-block;
                width: 45px;
                height: 45px;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                border: 4px solid #fff;
                padding: 0;
                color: #fff;

                &[disabled] {
                    cursor: not-allowed
                }
            }

            .playAudio {
                padding-left: 4px;
            }

        }
    }

    @media screen and (max-width: 991px) {
        padding-bottom: 67px;

        .wrapper {
            padding: 10px 0;

            ._cl76 {
                display: none;

                img{
                    max-width: 100%;
                }
            }

            .controls{
                padding: 0 20px;

                .playAudio, .pauseAudio{
                    margin: 0 20px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding-bottom: 106px;

        &._fxpl {
            .wrapper{
                transform: translateY(100%);
                transition: all 0.25s;
            }

            &._shw{
                .wrapper{
                    transform: translateY(0%);
                }
            }
        }
        .volume{
            display: none;
        }
        ._cl80c{
            flex-direction: column-reverse;

            .musicName{
                margin-top: 15px;
            }
        }
    }
}

/**
 *
 ** Modal
 *
 ***/
._modal{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;z-index: 1026;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;

    .close{
        background: none;
        border: 0;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        padding: 0;

        svg, path{
            transition: all 0.25s;
        }
        &:hover{
            svg, path{
                fill: $defaultColor;
            }
        }
    }
}
.entry-link{
    font-family: "Bebas Neue",sans-serif;
    border: none;
    background: none;
    padding: 0;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    display: block;

    span{
        font-family: 'bebas_neuebook';
    }
}

button.entry-link {
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
        color: $defaultColor;
    }
}

.addNewPlayList{
    ._frmg{
        margin-bottom: 35px;

        input{
            height: 48px;
            width: 100%;
            border: 1px solid #DEB421;
            background-color: transparent;
            font-family: 'bebas_neuebook';
            font-style: normal;
            font-weight: normal;
            font-size: 30px;
            line-height: 24px;
            color: white;
            padding: 10px;
            text-align: center;
            text-transform: none;

            &:focus {
                border: 1px solid #DEB421;
            }

            &:placeholder-shown {
                border: 1px solid #585858;
            }

            &::-webkit-input-placeholder
            &::-moz-placeholder
            &:-ms-input-placeholder
            &:-moz-placeholder { /* Firefox 18- */
                color: rgba(130, 130, 130, 0.5);
            }
        }
    }
    .button{
        svg, path{
            fill: #333;
        }
    }
    .goBack{
        text-align: center;
        padding-top: 40px;

        button {
            background: none;
            border: none;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            color: #585858;
            padding: 0;
            cursor: pointer;

            &:hover {
                color: $defaultColor;
                transition: all 0.25s;
            }
        }
    }
}
.entry-list{
    position: relative;

    .active{
        + label{
            &:after{
                transform: rotate(-135deg);
                top: calc(50% - 2px);
            }
        }
    }

    > select {
        bottom: 0;
        border: 1px solid transparent;
        left: 0;
        height: 100%;
        position: absolute;
        opacity: 0;
        right: 0;
        top: 0;
        width: 100%;
        cursor: pointer;
    }

    &.selected{
        .goBack{
            position: absolute;
            top: calc(100% + 40px);
            left: 50%;
            transform: translateX(-50%);
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            color: #585858;
            white-space: nowrap;

            button {
                cursor: pointer;
                background: none;
                border: none;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                font-weight: 500;
                font-family: 'Roboto', sans-serif;
                color: #585858;
                padding: 0;

                &:hover {
                    color: $defaultColor;
                    transition: all 0.25s;
                }
            }
        }
        label{
            position: relative;
            padding-right: 53px;
            padding-left: 5px;

            .leftBtn{
                position: absolute;
                right: 0;
                top: 0;
                height: 46px;
                width: 47px;
                background-color: $defaultColor;
                color: #333;
                line-height: 39px;
                text-align: center;
                border: 1px solid $defaultColor;
                border-radius: 0;
                font-size: 21px;
                transition: all 0.25s;

                &:hover{
                    background-color: #fff;
                }
            }
            &:after{
                display: none;
            }
        }
    }
    label{
        height: 48px;
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.4);
        font-size: 30px;
        line-height: 48px;
        letter-spacing: 0.1px;
        text-align: center;
        color: #fff;
        position: relative;
        cursor: pointer;
        padding: 0 30px;

        &:after{
            content: '';
            position: absolute;
            right: 15px;
            top: calc(50% - 10px);
            transform: rotate(45deg);
            width: 10px;
            height: 10px;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
        }
        span{
            font-family: 'bebas_neuebook';
        }
    }

    .list{
        position: absolute;
        left: 0;top: 100%;
        width: 100%;
        height: 215px;
        overflow: auto;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-top: none;
        padding: 15px 0;

        ._itm{
            font-size: 24px;
            line-height: 24px;
            font-family: 'bebas_neuebook';
            text-align: center;
            padding: 3px 0;
            transition: all 0.25s;
            cursor: pointer;
            color: #fff;

            svg{
                opacity: 0;
                transition: all 0.25s;
            }

            &:hover{
                color: $defaultColor;

                svg{
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 1199px){
    .table-colored {
        tbody {
            tr {
                td{
                    &:first-child{
                        padding-left: 15px;
                    }
                    &:last-child{
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px){
    .h2{
        font-size: 52px;
        line-height: 52px;
        margin: 0;
        margin-bottom: 25px;
    }
    ._visible-sm{
        display: block;
    }
    ._hidden-sm{
        display: none;
    }
    .fw-bold-sm{
        font-family: $fonts;
    }
    .m-0-80{
        margin: 0 20px;
    }
    .table{
        display: block;
        margin: 0 -15px;
        width: auto;
        background-color: #fff;
        padding-top: 30px;


        thead{
            display: none;
        }
        tbody{
            display: block;

            tr{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 20px 15px;
                border-bottom: 2px solid #000;


                &:first-child{
                    padding-top: 0;
                }
                td, th{
                    border-top: none;
                    padding: 0;
                    line-height: 30px;

                    &:nth-child(1){
                        order: 4;

                        svg{
                            display: block;
                            margin: 10px auto 0 auto;
                        }
                        button{
                            display: none;
                        }
                    }
                    &:nth-child(6){
                        order: 3;
                    }
                    &:nth-child(3){
                        order: 2;
                    }
                    &:nth-child(2){
                        order: 6;

                        svg, path{
                            fill: $primaryColor;
                        }
                    }
                    &:nth-child(7){
                        order: 5;
                    }
                    &:nth-child(8){
                        order: 1;

                        svg, path{
                            fill: $defaultColor;
                        }
                    }
                }
                &:last-child{
                    border-bottom: none;
                }
                &.active{
                    font-family: 'bebas_neuebook';
                    color: inherit;
                }
            }
        }
    }

    .table-colored {
        tbody {
            tr {
                td{
                    &:first-child{
                        padding-left: 15px;
                    }
                    &:last-child{
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px){
    .vs-mobile{
        display: block;
    }
    .h1{
        font-size: 36px;
        line-height: 36px;
        margin: 0;
        margin-bottom: 10px;
    }
    .entry-list{
        max-width: 400px;
        label{
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }

    }
    .table-colored{
        display: block;

        tbody{
            display: block;
            tr{
                display: block;
                border-top: 1px solid #fff;
                padding-top: 20px;
                padding-bottom: 20px;

                &:nth-child(even){
                    background-color: #323232;
                }
                td{
                    display: block;
                    width: 100% !important;
                    border-top: none;
                    padding: 2px 15px;
                    text-align: center !important;

                    &:first-child{
                        display: flex;
                        flex-direction: column-reverse;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 480px){
    .entry-link{
        font-size: 33px;
    }
    .entry-list{
        max-width: 300px;

        .list {
            ._itm{
                font-size: 20px;
                line-height: 20px;

                svg, path{
                    opacity: 1;
                    fill: #fff;
                }
            }
        }
    }
}

.map-marker {
    background-image: url('../img/mappin.svg');
    background-size: cover;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.dsp-select {
    margin: 30px auto 0;
    max-width: 300px;

    .spotify {
        background: url('../img/spotify.png') no-repeat;
        cursor: pointer;
        border: 0;
        height: 0;
        padding: (709/2362)*100% 0 0;
        margin: 0 0 40px;
        background-size: contain;
        text-indent: -10000px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .apple {
        background: url('../img/applemusic.svg') no-repeat;
        cursor: pointer;
        border: 0;
        height: 0;
        padding: (41/141)*100% 0 0;
        background-size: contain;
        text-indent: -10000px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    .youtube{
        background: url('../img/YouTube-Logo.png') no-repeat;
        cursor: pointer;
        border: 0;
        height: 0;
        padding: (709/2362)*100% 0 0;
        margin: 0 0 25px;
        background-size: contain;
        text-indent: -10000px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
}

.not-found {
    background: white;
}

@keyframes Loading_dash__3GYPa{
    0%{stroke-dashoffset:1500}
    40%{stroke-dashoffset:3000}
    50%{stroke-dashoffset:3000}
    to{stroke-dashoffset:1500}
}

.loading {
    padding: 100px 0;

    svg {
        display: block;
        margin: 0 auto;
        max-width: 600px;
        width: 100%;

        path {
            stroke-dasharray: 3000;
            stroke-dashoffset: 1500;
            -webkit-animation: Loading_dash__3GYPa 6s infinite;
            animation: Loading_dash__3GYPa 6s infinite;
        }
    }
}

.fan-recap {
    background-color: white;
    padding-top: 85px;
    padding-bottom: 135px;
    border-top: 10px solid $defaultColor;

    &-content {
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 42px;
            line-height: 48px;
            letter-spacing: -0.1px;
            color: #DEB421;
            margin-bottom: 12px;
            background-color: transparent;
            border: none;
            font-family: inherit;

            @media screen and (max-width: 670px) {
                line-height: 0px;
            }
        }

        p {
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.1px;
            font-weight: 500;
            font-family: 'Roboto', sans-serif;
            color: #333;
        }

        & > div {
            display: flex;
            flex-direction: row;
            width: 100%;

            @media screen and (max-width: 670px) {
                flex-direction: column;
            }

            & > div {
                width: 100%;
            }
        }
    }
}

.rating {
    display: flex;
    align-items: flex-end;

    &-indicator {
        background: none;
        color: inherit;
        border: none;
        display: inline-block;
        padding: 5px;
        font: inherit;
        outline: inherit;
    }

    &.enabled &-indicator {
        cursor: pointer;
    }

    &-label {
        color: rgba(255, 255, 255, 0.6);
        padding-bottom: 6px;
        letter-spacing: 1px;
    }
}
